// Bootstrap imports
import Alert from 'bootstrap/js/dist/alert';
import Popover from 'bootstrap/js/dist/popover';

/**
 * Dismiss alerts automatically after 3 seconds
 */
export function initAutoDismissAlerts() {
  const alertList = document.querySelectorAll('.alert-dismissible');
  alertList.forEach(function (alert) {
    setTimeout(function () {
      const bsAlert = Alert.getOrCreateInstance(alert);
      if (bsAlert !== null) {
        bsAlert.close();
      }
    }, 3000);
  });
}

/**
 * Initialize Bootstrap popovers
 */
export function initPopovers() {
  const popoverElements = document.querySelectorAll('[data-bs-toggle="popover"]');
  popoverElements.forEach((popoverElement) => {
    new Popover(popoverElement);
  });
}

/**
 * Initialize fullscreen toggle functionality
 */
export function initFullscreenToggle() {
  const fullScreenToggle = document.getElementById('fullScreenToggle');
  const mainContainer = document.querySelector('main .container');

  if (!fullScreenToggle || !mainContainer) {
    // If either element is not found, exit the function
    return;
  }

  function toggleFullscreen() {
    mainContainer.classList.toggle('container');
    mainContainer.classList.toggle('container-fluid');

    const isFullScreen = mainContainer.classList.contains('container-fluid');
    fullScreenToggle.setAttribute('aria-pressed', isFullScreen);

    const icon = fullScreenToggle.querySelector('i');
    if (icon) {
      if (isFullScreen) {
        icon.classList.remove('bi-arrows-fullscreen');
        icon.classList.add('bi-fullscreen-exit');
      } else {
        icon.classList.remove('bi-fullscreen-exit');
        icon.classList.add('bi-arrows-fullscreen');
      }
    }

    localStorage.setItem('containerPreference', isFullScreen ? 'fluid' : 'fixed');
  }

  fullScreenToggle.addEventListener('click', toggleFullscreen);

  // Check localStorage for saved preference
  const savedPreference = localStorage.getItem('containerPreference');
  if (savedPreference === 'fluid') {
    toggleFullscreen();
  }
}
